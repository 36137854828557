import { getContext } from '@fiverr-private/fiverr_context';
import { startQualtrics } from './qualtrics';
import { startOneTrust } from './onetrust';

/**
 * Start Qualtrics and OneTrust analytics
 */
const startAnalytics = () => {
    const { isTouch } = getContext();
    startOneTrust();

    if (!isTouch) {
        startQualtrics();
    }
};

export default startAnalytics;
